@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&family=Montserrat:wght@300;400;500;600;700&display=swap");

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

body {
  margin: 0;
  padding: 0;
}

body,
html,
* {
  box-sizing: border-box !important ;
  font-family: "Cabin", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

h1,
h2,
h3,
h4,
label {
  font-family: "Montserrat", sans-serif;
}
.slick-arrow.slick-prev,
.slick-arrow.slick-next {
  display: none !important;
}
/* .slick-slider {
  height : 100% !important;
  display: "flex" !important;
  justify-content : "center" !important;
  align-items: "center" !important;
} */
.bar-height{
  height: 342px !important;
}