.custom_radio_btn {
    width: 18px;
    height: 18px;
    border: 2px solid #702963;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.custom_radio_btn .checkmark {
    width: 10px;
    height: 10px;
    background-color: #702963;  
    border-radius: 50%;
} 
.custom_radio_btn input {
   width: 0;
   height: 0;
}
.custom_radio_btn input:checked + .checkmark {
    display: inline-block;
}